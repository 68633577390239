import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../components/layout"
import ContentPage from "../../layouts/contentPage"

import Seo from "../../components/seo"

import ProductHero from "../../components/Product/productHero"
import H1 from "../../components/h1"
import ButtonColor from "../../components/buttonColor"
import ProductInfo from "../../components/Product/productInfo"
import ProductPackshot from "../../components/Product/productPackshot"
import ProductPackshotBubbles from "../../components/Product/productPackshotBubbles"
import BubbleBg from "../../components/Product/bubbleBg"
import ProductSidebar from "../../components/Product/productSidebar"
import More from "../../components/more"
import Tabs from "../../components/Tabs/tabs"
import ProductDescription from "../../components/Product/productDescription"
import Relift from "../../images/media/products/relift/relift.svg";
import Ingredients from "../../components/Product/ingredients"
import Ingredient from "../../components/Product/ingredient"
import Effectivness from "../../components/Product/effectivness"
import EffectivnessCard from "../../components/Product/effectivnessCard"
import ActiveIngredients from "../../components/Product/activeIngredients"
import ActiveIngredient from "../../components/Product/activeIngredient"
import DescriptionPackshot from "../../components/Product/descriptionPackshot"
import Container from "../../components/Product/container"






const Product = () => (
    <Layout>
      <Seo title="Odmładzające serum do twarzy na dzień SPF 15 - Linia Relift" />
        <ProductHero>

            {/* Info */}
            <div data-column>
                <ProductInfo color="magenta">
                    <H1>Odmładzające serum do twarzy <br/>na dzień SPF 15</H1>
                    <p>Zastrzyk witaminowy dla dojrzałej skóry</p>
                    <ProductPackshot mobile>
                        <StaticImage
                        src="../../images/media/products/relift/products/odmladzajace-serum.png"
                        loading="eager"
                        width={580}
                        quality={95}
                        placeholder="none"
                        formats={["auto", "webp", "avif"]}
                        alt="Energetyzujące serum"
                        />
                        <ProductPackshotBubbles/>
                </ProductPackshot>
                    <ul>
                        <li>Redukuje zmarszczki i wygładza skórę.</li>
                        <li>Uelastycznia i silnie odżywia.</li>
                        <li>Dotlenia i usprawnia funkcjonowanie komórek.</li>
                        <li>Wspiera mikrobiom i odbudowuje barierę ochronną.</li>
                    </ul>
                    <Container>
                    <ButtonColor as="a" target="_blank" href="https://www.hebe.pl/oxygenetic-odmladzajace-serum-do-twarzy-na-dzien-50-ml-000000000000407513.html" color="magenta">Kup w Hebe</ButtonColor>
                    <ButtonColor as="a" target="_blank" href="https://www.zikodermo.pl/oxygenetic-relift-odmladzajace-serum-do-twarzy-na-dzien-spf-15-50-ml.html" color="magenta">Kup w Ziko Dermo</ButtonColor>
                    <ButtonColor as="a" target="_blank" href="https://gemini.pl/oxygenetic-relift-vitamin-a-odmladzajace-serum-do-twarzy-na-dzien-spf-15-50-ml-0107790" color="magenta">Kup w Gemini</ButtonColor>
                    <ButtonColor as="a" target="_blank" href="https://www.doz.pl/apteka/p176929-Oxygenetic_Relift_Vitamin_A_odmladzajace_serum_do_twarzy_na_dzien_SPF_15_50_ml" color="magenta">Kup w DOZ</ButtonColor>
                    </Container>
                </ProductInfo>
            </div>

            {/* Packshot */}
            <div data-column>
                <ProductPackshot>
                        <StaticImage
                        src="../../images/media/products/relift/products/odmladzajace-serum.png"
                        loading="eager"
                        width={580}
                        quality={95}
                        placeholder="none"
                        formats={["auto", "webp", "avif"]}
                        alt="liftingujace serum"
                        />
                        <ProductPackshotBubbles/>
                </ProductPackshot>
                <More url="#opis-produktu" color="magenta" text="więcej"/>
            </div>

            {/* Category Products */}
            <div data-column>
                <ProductSidebar>
                <Link to="/produkty/wygladzajace-serum">
                        <StaticImage
                             src="../../images/media/products/relift/products/wygladzajace-serum.png"
                            loading="eager"
                            // width={142}
                            quality={95}
                            placeholder="blurred"
                            formats={["auto", "webp", "avif"]}
                            alt="wygladzajace serum"
                        />
                    </Link>
                    <Link to="/produkty/liftingujace-serum">
                        <StaticImage
                             src="../../images/media/products/relift/products/liftingujace-serum.png"
                            loading="eager"
                            // width={142}
                            quality={95}
                            placeholder="blurred"
                            formats={["auto", "webp", "avif"]}
                            alt="liftingujace serum"
                        />
                    </Link>
                    <Link to="/produkty/ultranaprawcze-serum">
                        <StaticImage
                             src="../../images/media/products/relift/products/ultranaprawcze-serum.png"
                            loading="eager"
                            // width={142}
                            quality={95}
                            placeholder="blurred"
                            formats={["auto", "webp", "avif"]}
                            alt="ultranaprawcze serum"
                        />
                    </Link>
                </ProductSidebar>
            </div>
            {/* <BubbleBg/> */}
        </ProductHero> 
        <section id="opis-produktu">

            {/* TABS */}
            <Tabs color="magenta">

                {/* OPIS */}
                <div label="Opis" className="tab-list-active">
                <ProductDescription color="magenta">
                    <div className="descriptionRow">
                        <div className="column">
                            <div className="text--left">

                                <p>
                                    <strong>Serum skutecznie eliminuje oznaki starzenia, redukując zmarszczki i wygładzając skórę.</strong>
                                </p>
                                <p>
                                    Zwiększa syntezę kolagenu i elastyny w skórze, widocznie ją ujędrnia i uelastycznia, zapewniając jej młodszy wygląd. Dodatkowo serum spowalnia procesy starzenia, chroniąc komórki przed negatywnym wpływem wolnych rodników i promieni UV.
                                </p>
                                <p>
                                    Ponadto preparat, dzięki zdolności łączenia się z lipidami naskórka i pobudzania fibroblastów, mocno odżywia oraz silnie regeneruje skórę poprawiając jej strukturę. Efekt to widocznie odmłodzona, wygładzona, napięta i silnie odżywiona skóra.
                                </p>
                            </div>
                        </div>
                        <div className="column">
                            <DescriptionPackshot>
                                <StaticImage
                                src="../../images/media/products/relift/products/odmladzajace-serum_single.png"
                                loading="lazy"
                                // width={534}
                                quality={95}
                                placeholder="none"
                                formats={["auto", "webp", "avif"]}
                                alt="odmladzajace serum"
                             />
                            </DescriptionPackshot>
                        </div>
                        <div className="column">
                            <div className="text--right">
                                <p><strong>Sposób użycia:</strong></p>
                                <p>Stosować codziennie rano na oczyszczoną skórę twarzy. Tylko do użytku zewnętrznego. Unikać bezpośredniego kontaktu z oczami.</p>
                                <br/>
                                <p><strong>Pojemność:</strong></p>
                                <p>50ml</p>
                                <Relift/>
                            </div>
                        </div>
                    </div>        
                </ProductDescription>
                </div>

                {/* BAZA */}
                <div label="Baza">
                <Ingredients>
                    <Ingredient name="Retinol z adenozyną" desc="To połączenie składników, które przyspiesza odbudowę skóry 
usuwając stare komórki i zastępując je nowymi. 
Ponadto pobudza produkcję kolagenu i elastyny, 
spłycając istniejące zmarszczki i chroniąc przed 
powstawaniem nowych. W konsekwencji skóra 
jest wygładzona, napięta i rozświetlona." />
                    <Ingredient name="Kompleks dotleniających minerałów" desc="Składnik, który wiąże cząsteczki tlenu zwiększając ich dostępność w skórze oraz stymuluje metabolizm komórkowy. Dzięki czemu komórki skóry otrzymują niezbędną energię, zaczynają prawidłowo funkcjonować i regenerować się. W efekcie znacznie poprawia się wygląd skóry, staje się ona zdrowa i promienna." />
                    <Ingredient name="Synbiotyk" desc="To kompleks wzajemnie uzupełniającego się probiotyku i prebiotyku, który wzmacnia naturalną warstwę ochronną skóry - mikrobiom, chroniąc przed niekorzystnymi czynnikami zewnętrznymi. Dodatkowo wykazuje działanie nawilżające, odżywcze i wygładzające." />
                </Ingredients>
                </div>

                {/* SKŁADNIKI AKTYWNE */}
                <div label="Składniki aktywne">
                <ActiveIngredients>
                    <ActiveIngredient name="Wyciąg z owoców granatu, figi, morwy i miłorzębu" desc="Ekstrakty skutecznie poprawiające elastyczność skóry oraz redukujące istniejące zmarszczki. Bogate w antyoksydanty, takie jak kwasy fenolowe oraz flawonoidy, neutralizują wolne rodniki, chroniąc skórę przed starzeniem." />
                    <ActiveIngredient name="Olej z orzechów macadamia" desc="Składnik, który tworzy hydrofobowy film na skórze, który odpowiada za natłuszczanie, zmiękczanie, wygładzenie, a także pośrednie nawil­żanie skóry poprzez ograniczenie transepidermalnej utracie wody (TEWL). Dodatkowo działa ujędrniająco i uelastyczniająco." />
                    <ActiveIngredient name="Panthenol" desc="Prowitamina B5, która przenikając do głębszych warstw skóry, nawilża ją i łagodzi podrażnienia. Dodatkowo redukuje transepidermalną utratę wody (TEWL) i przywraca równowagę hydrolipidową." />
                </ActiveIngredients>
                </div>

                {/* SKUTECZNOŚĆ */}
                <div label="Skuteczność">
                <Effectivness>
                    <EffectivnessCard color="magenta" percentage="100" desc="badanych potwierdza, że po zastosowaniu skóra odzyskała zdrowy i promienny wygląd"/>
                    <EffectivnessCard color="magenta" percentage="87" desc="badanych potwierdza, że regularne stosowanie widocznie poprawia kontur i owal twarzy"/>
                    <EffectivnessCard color="magenta" percentage="97" desc="badanych potwierdza, że rozjaśnia drobne przebarwienia poprawiając koloryt skóry"/>
                </Effectivness>
                </div>
            </Tabs>
        </section>
    </Layout>
)

export default Product
